/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/popper.js@1.16.1/dist/umd/popper.min.js
 * - /npm/bootstrap@4.5.3/dist/js/bootstrap.min.js
 * - /npm/jquery.appear@1.0.1/jquery.appear.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/owl.carousel@2.1.1/dist/owl.carousel.min.js
 * - /npm/wow.js@1.2.2/dist/wow.min.js
 * - /npm/typewriter-effect@2.17.0/dist/core.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
